(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@turf/bbox-polygon"), require("@turf/intersect"), require("@turf/boolean-intersects"), require("@turf/boolean-within"), require("quadbin"), require("h3-js"), require("@turf/bbox-clip"));
	else if(typeof define === 'function' && define.amd)
		define(["@turf/bbox-polygon", "@turf/intersect", "@turf/boolean-intersects", "@turf/boolean-within", "quadbin", "h3-js", "@turf/bbox-clip"], factory);
	else if(typeof exports === 'object')
		exports["cartoReactCore"] = factory(require("@turf/bbox-polygon"), require("@turf/intersect"), require("@turf/boolean-intersects"), require("@turf/boolean-within"), require("quadbin"), require("h3-js"), require("@turf/bbox-clip"));
	else
		root["cartoReactCore"] = factory(root["@turf/bbox-polygon"], root["@turf/intersect"], root["@turf/boolean-intersects"], root["@turf/boolean-within"], root["quadbin"], root["h3-js"], root["@turf/bbox-clip"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__855__, __WEBPACK_EXTERNAL_MODULE__956__, __WEBPACK_EXTERNAL_MODULE__97__, __WEBPACK_EXTERNAL_MODULE__55__, __WEBPACK_EXTERNAL_MODULE__835__, __WEBPACK_EXTERNAL_MODULE__502__, __WEBPACK_EXTERNAL_MODULE__72__) => {
return 