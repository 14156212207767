(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@mui/material"), require("react"), require("@mui/system"), require("@mui/icons-material"), require("@mui/styles/makeStyles"), require("echarts-for-react"), require("@carto/react-core"), require("@mui/material/styles"), require("@mui/styles/withStyles"), require("@mui/material/Skeleton"));
	else if(typeof define === 'function' && define.amd)
		define(["@mui/material", "react", "@mui/system", "@mui/icons-material", "@mui/styles/makeStyles", "echarts-for-react", "@carto/react-core", "@mui/material/styles", "@mui/styles/withStyles", "@mui/material/Skeleton"], factory);
	else if(typeof exports === 'object')
		exports["cartoReactUi"] = factory(require("@mui/material"), require("react"), require("@mui/system"), require("@mui/icons-material"), require("@mui/styles/makeStyles"), require("echarts-for-react"), require("@carto/react-core"), require("@mui/material/styles"), require("@mui/styles/withStyles"), require("@mui/material/Skeleton"));
	else
		root["cartoReactUi"] = factory(root["@mui/material"], root["react"], root["@mui/system"], root["@mui/icons-material"], root["@mui/styles/makeStyles"], root["echarts-for-react"], root["@carto/react-core"], root["@mui/material/styles"], root["@mui/styles/withStyles"], root["@mui/material/Skeleton"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__901__, __WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__719__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__878__, __WEBPACK_EXTERNAL_MODULE__981__, __WEBPACK_EXTERNAL_MODULE__485__, __WEBPACK_EXTERNAL_MODULE__655__, __WEBPACK_EXTERNAL_MODULE__230__, __WEBPACK_EXTERNAL_MODULE__600__) => {
return 